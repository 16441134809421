import React from 'react';

const UICardAbilityContainer = ({ children, ...rest }) => {
    const defaultProps = {
        ...rest,
    };

    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...defaultProps}>
            {children}
        </svg>
    );
};

export default UICardAbilityContainer;
