import React from 'react';

const UICardSignature = ({ label, width, ...rest }) => {
    const defaultProps = {
        fill: '#968d6c',
        fontSize: 10,
        fontFamily: 'Cookbook',
        ...rest,
    };

    const style = {
        textAnchor: 'middle',
        dominantBaseline: 'middle',
    };

    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <text {...defaultProps} style={style} transform={`translate(${width / 2} 0)`}>
                {label}
            </text>
        </svg>
    );
};

export default UICardSignature;
