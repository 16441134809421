import React from 'react';

const UICardDrawing = ({ href, width, height, transform, ...rest }) => {
    const offset = 5;
    const defaultProps = {
        x: offset,
        y: offset,
        width: width - offset * 2,
        height: height - offset * 2,
    };

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox={`0 0 ${width} ${height}`}
            {...defaultProps}
        >
            <g width="100%" height="100%" transform={transform}>
                <image width="100%" height="100%" href={href} {...rest} />
            </g>
        </svg>
    );
};

export default UICardDrawing;
