import React from 'react';

import { Form, InputGroup } from 'react-bootstrap';
import TextDescription from './TextDescription';

const UIInputCount = (props) => {
    const { id, label, description, min, max, value, ...rest } = props;

    const onFocus = (e) => {
        e.target.select();
    };

    return (
        <>
            <InputGroup>
                <InputGroup.Text id={id}>{label}</InputGroup.Text>
                <Form.Control
                    type="number"
                    min={min}
                    max={max}
                    value={value}
                    onFocus={onFocus}
                    onKeyUp={onFocus}
                    aria-describedby={id}
                    {...rest}
                />
            </InputGroup>
            {description && <TextDescription>{description}</TextDescription>}
        </>
    );
};

export default UIInputCount;
