import React from 'react';

import Flags from 'country-flag-icons/react/3x2';

const UILanguageFlag = ({ name, countryCode }) => {
    const Flag = Flags[countryCode];

    return (
        <>
            <Flag title={name.toUpperCase()} className="flag" style={{ width: '1.75em' }} />
            <span className="ms-2">{name.toUpperCase()}</span>
        </>
    );
};

export default UILanguageFlag;
