import React from 'react';

import Button from 'react-bootstrap/Button';
import { BiDownload } from 'react-icons/bi';

const UIDownloadButton = ({ label, ...rest }) => (
    <Button {...rest} variant="primary" size="lg">
        <BiDownload style={{ fontSize: '1.25em' }} />
        <span className="ms-3">{label}</span>
    </Button>
);

export default UIDownloadButton;
