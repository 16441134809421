import React from 'react';

const getConfig = (str) => {
    if (str.length > 20) {
        return {
            fontSize: 18,
            transform: 'translate(0 -3)',
        };
    }
    if (str.length > 12) {
        return {
            fontSize: 22,
            transform: 'translate(0 -2)',
        };
    }
    return {
        fontSize: 33,
        transform: 'translate(0 -2)',
    };
};

const UICardTitle = ({ label }) => {
    const { fontSize, transform } = getConfig(label);

    const defaultProps = {
        fill: '#45382f',
        fontFamily: 'Cookbook',
        fontSize,
    };

    const style = {
        textAnchor: 'middle',
        dominantBaseline: 'middle',
    };

    const pathId = 'titlePath';

    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <refs>
                <path id={pathId} fill="none" d="M 50 392 A 160 40 -180 0 1 350 392" />
            </refs>
            <text {...defaultProps} transform={transform} style={style}>
                <textPath startOffset="50%" xlinkHref={`#${pathId}`}>
                    {label}
                </textPath>
            </text>
        </svg>
    );
};

export default UICardTitle;
