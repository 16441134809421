/* eslint-disable no-restricted-properties */
/* eslint-disable no-plusplus */
import { useState, useRef } from 'react';

const interval = 35;

const useDrag = (hasDrawing, { x = 0, y = 0 }) => {
    const timer = useRef(null);
    let startTime;

    const baseInfo = {
        isActive: false,
        x,
        y,
        offset: {
            x: 0,
            y: 0,
        },
    };

    const [info, setInfo] = useState(baseInfo);

    const resetInfo = () => {
        setInfo(baseInfo);
    };

    const handlePointerDown = ({ pointerId, target, clientX, clientY }) => {
        if (!hasDrawing) {
            return;
        }

        const bbox = target.getBoundingClientRect();
        const posX = clientX - bbox.left;
        const posY = clientY - bbox.top;

        target.setPointerCapture(pointerId);
        setInfo({
            ...info,
            isActive: true,
            offset: {
                x: posX - info.x,
                y: posY - info.y,
            },
        });
    };

    const handlePointerMove = ({ target, clientX, clientY }) => {
        if (!hasDrawing) {
            return;
        }

        if (info.isActive) {
            const bbox = target.getBoundingClientRect();
            const posX = clientX - bbox.left;
            const posY = clientY - bbox.top;

            setInfo({
                ...info,
                x: posX - info.offset.x,
                y: posY - info.offset.y,
            });
        }
    };

    const handlePointerUp = () => {
        if (!hasDrawing) {
            return;
        }

        setInfo({
            ...info,
            isActive: false,
        });
    };

    const getOffset = () => 1 + Math.floor((Date.now() - startTime) / 250);

    const moveLeft = () => {
        if (!hasDrawing) {
            return;
        }

        let positionX = info.x;
        startTime = Date.now();

        timer.current = setInterval(() => {
            positionX -= getOffset();

            setInfo({
                ...info,
                x: positionX,
            });
        }, interval);
    };

    const moveRight = () => {
        if (!hasDrawing) {
            return;
        }

        let positionX = info.x;
        startTime = Date.now();

        timer.current = setInterval(() => {
            positionX += getOffset();

            setInfo({
                ...info,
                x: positionX,
            });
        }, interval);
    };

    const moveUp = () => {
        if (!hasDrawing) {
            return;
        }

        let positionY = info.y;
        startTime = Date.now();

        timer.current = setInterval(() => {
            positionY -= getOffset();

            setInfo({
                ...info,
                y: positionY,
            });
        }, interval);
    };

    const moveDown = () => {
        if (!hasDrawing) {
            return;
        }

        let positionY = info.y;
        startTime = Date.now();

        timer.current = setInterval(() => {
            positionY += getOffset();

            setInfo({
                ...info,
                y: positionY,
            });
        }, interval);
    };

    const clearTimer = () => {
        clearInterval(timer.current);
    };

    const elementPosition = {
        x: info.x,
        y: info.y,
    };

    return {
        elementPosition,
        handlePointerDown,
        handlePointerMove,
        handlePointerUp,
        moveLeft,
        moveRight,
        moveUp,
        moveDown,
        clearTimer,
        resetInfo,
    };
};

export default useDrag;
