/* eslint-disable global-require */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { LANGUAGE as LANGUAGE_CONFIG } from '../config';
import LanguageList from '../config/LanguageList';

i18n.use(initReactI18next).init({
    fallbackLng: LANGUAGE_CONFIG.FALLBACK,
    lng: LANGUAGE_CONFIG.DEFAULT,
    resources: {
        en: {
            translations: require('./locales/EN.json'),
        },
        fr: {
            translations: require('./locales/FR.json'),
        },
    },
    ns: ['translations'],
    defaultNS: 'translations',
});

i18n.languages = LanguageList.map(({ name }) => name);

export default i18n;
