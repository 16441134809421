export default [
    {
        name: 'en',
        countryCode: 'GB',
    },
    {
        name: 'fr',
        countryCode: 'FR',
    },
];
