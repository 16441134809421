/* eslint-disable default-case-last */
import React, { useRef, useEffect } from 'react';

import { Row, Col, Button } from 'react-bootstrap';
import { FaArrowDown, FaArrowUp, FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { BiZoomIn, BiZoomOut } from 'react-icons/bi';
import useDrag from '../../hooks/useDrag';
import useZoom from '../../hooks/useZoom';

import { CardAbility, CardAbilityContainer, CardBackground, CardDrawing, CardNumber, CardSignature, CardTitle } from './Card/index';

import { BACKGROUND_PATH, DRAWING_BACKGROUND_PATH } from '../../config';

const TITLE_LIMIT = 25;
const SIGNATURE_LIMIT = 25;

const formatText = (str, limit = 0) => {
    let ret = str.toUpperCase();
    if (limit) {
        ret = ret.substring(0, limit);
    }
    return ret;
};

const UICard = (props) => {
    const svgRef = useRef();

    const ratio = 0.6;
    const defaultProps = {
        width: 661 * ratio,
        height: 1041 * ratio,
    };

    const { svgId, cardType, drawing, drawingBackground, mana, attack, health, abilityList, title, signature } = props;

    const containsAura = abilityList.filter(({ isAura }) => isAura).length;

    const abilitySize = 55;
    const abilityOffset = 10;

    const positions = [];
    if (containsAura) {
        switch (abilityList.length) {
            default:
            case 1:
                positions.push({ x: defaultProps.width / 2, y: abilitySize / 2 });
                break;
            case 2:
                positions.push({ x: defaultProps.width / 2, y: 0 });

                positions.push({ x: defaultProps.width / 2, y: abilitySize });
                break;
            case 3:
                positions.push({ x: defaultProps.width / 2, y: 0 });

                positions.push({ x: defaultProps.width / 2 - abilitySize * 0.5, y: abilitySize });
                positions.push({ x: defaultProps.width / 2 + abilitySize * 0.5, y: abilitySize });
                break;
        }
    } else {
        switch (abilityList.length) {
            default:
            case 1:
                positions.push({ x: defaultProps.width / 2, y: abilitySize / 2 });
                break;
            case 2:
                positions.push({ x: defaultProps.width / 2 - abilitySize * 0.5, y: abilitySize / 2 });
                positions.push({ x: defaultProps.width / 2 + abilitySize * 0.5, y: abilitySize / 2 });
                break;
            case 3:
                positions.push({ x: defaultProps.width / 2 - abilitySize * 0.5, y: 0 });
                positions.push({ x: defaultProps.width / 2 + abilitySize * 0.5, y: 0 });

                positions.push({ x: defaultProps.width / 2, y: abilitySize });
                break;
            case 4:
                positions.push({ x: defaultProps.width / 2 - abilitySize * 1, y: 0 });
                positions.push({ x: defaultProps.width / 2, y: 0 });
                positions.push({ x: defaultProps.width / 2 + abilitySize * 1, y: 0 });

                positions.push({ x: defaultProps.width / 2, y: abilitySize });
                break;
            case 5:
                positions.push({ x: defaultProps.width / 2 - abilitySize * 1, y: 0 });
                positions.push({ x: defaultProps.width / 2, y: 0 });
                positions.push({ x: defaultProps.width / 2 + abilitySize * 1, y: 0 });

                positions.push({ x: defaultProps.width / 2 - abilitySize * 0.5, y: abilitySize });
                positions.push({ x: defaultProps.width / 2 + abilitySize * 0.5, y: abilitySize });
                break;
        }
    }

    const {
        elementPosition,
        handlePointerDown,
        handlePointerUp,
        handlePointerMove,
        moveLeft,
        moveRight,
        moveUp,
        moveDown,
        clearTimer: clearMoveTimer,
        resetInfo,
    } = useDrag(!!drawing, { x: 0, y: 0 });
    const { elementScale, setScale, zoomIn, zoomOut, clearTimer: clearZoomTimer } = useZoom(!!drawing, svgRef);

    useEffect(() => {
        setScale(0.8);
        resetInfo();
    }, [drawing]);

    return (
        <>
            <svg id={svgId} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...defaultProps}>
                {drawingBackground && <CardBackground href={DRAWING_BACKGROUND_PATH + drawingBackground} {...defaultProps} />}
                {drawing && <CardDrawing href={drawing} {...defaultProps} {...elementPosition} {...elementScale} />}
                <CardBackground
                    ref={svgRef}
                    href={`${BACKGROUND_PATH + cardType}.png`}
                    onPointerDown={handlePointerDown}
                    onPointerUp={handlePointerUp}
                    onPointerMove={handlePointerMove}
                    {...defaultProps}
                />

                <g>
                    <CardNumber x="71" y="76" label={mana} />
                    <CardNumber x="69" y="558" label={attack} />
                    {cardType !== 'incantation' && <CardNumber x="330" y="556" label={health} />}
                </g>
                <CardTitle label={formatText(title, TITLE_LIMIT)} {...defaultProps} />
                <CardAbilityContainer width="100%" x="0" y="425">
                    {abilityList.map(({ href, isAura = false }, idx) => {
                        const pos = positions[idx] || { x: 0, y: 0 };
                        return (
                            <CardAbility key={idx} href={href} isAura={isAura} position={pos} size={abilitySize} offset={abilityOffset} />
                        );
                    })}
                </CardAbilityContainer>
                <CardSignature y="600" label={formatText(signature, SIGNATURE_LIMIT)} {...defaultProps} />
            </svg>

            {drawing && (
                <Row className="mt-3">
                    <Col className="text-center">
                        <Button
                            className="mx-2"
                            onTouchEnd={clearZoomTimer}
                            onTouchStart={zoomIn}
                            onMouseUp={clearZoomTimer}
                            onMouseDown={zoomIn}
                        >
                            <BiZoomIn />
                        </Button>
                        <Button
                            className="mx-2"
                            onTouchEnd={clearMoveTimer}
                            onTouchStart={moveLeft}
                            onMouseUp={clearMoveTimer}
                            onMouseDown={moveLeft}
                        >
                            <FaArrowLeft />
                        </Button>
                        <Button
                            className="mx-2"
                            onTouchEnd={clearMoveTimer}
                            onTouchStart={moveUp}
                            onMouseUp={clearMoveTimer}
                            onMouseDown={moveUp}
                        >
                            <FaArrowUp />
                        </Button>
                        <Button
                            className="mx-2"
                            onTouchEnd={clearMoveTimer}
                            onTouchStart={moveDown}
                            onMouseUp={clearMoveTimer}
                            onMouseDown={moveDown}
                        >
                            <FaArrowDown />
                        </Button>
                        <Button
                            className="mx-2"
                            onTouchEnd={clearMoveTimer}
                            onTouchStart={moveRight}
                            onMouseUp={clearMoveTimer}
                            onMouseDown={moveRight}
                        >
                            <FaArrowRight />
                        </Button>
                        <Button
                            className="mx-2"
                            onTouchEnd={clearZoomTimer}
                            onTouchStart={zoomOut}
                            onMouseUp={clearZoomTimer}
                            onMouseDown={zoomOut}
                        >
                            <BiZoomOut />
                        </Button>
                    </Col>
                </Row>
            )}
        </>
    );
};
export default React.memo(UICard);
