import React from 'react';

import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';

import AbilityPicture from './AbilityPicture';

const UIButtonAbility = (props) => {
    const { name, href, description, isAura, onClick, isSelected, disabled } = props;
    const borderColorClass = isSelected ? 'border-primary' : 'border-transparent';

    return (
        <OverlayTrigger
            overlay={
                <Tooltip id="tooltip-disabled" className="custom-tooltip">
                    <div dangerouslySetInnerHTML={{ __html: description || name }} />
                </Tooltip>
            }
        >
            <Button
                variant="transparent"
                className={`border border-3 ${borderColorClass} p-1 me-1 mb-1`}
                onClick={onClick}
                disabled={disabled}
            >
                <AbilityPicture name={name} href={href} isAura={isAura} />
            </Button>
        </OverlayTrigger>
    );
};

export default UIButtonAbility;
