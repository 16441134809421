import React from 'react';

import { Image } from 'react-bootstrap';

import { ABILITY_PATH } from '../../config';

const UIAbilityPicture = ({ name, href, isAura }) => {
    const src = ABILITY_PATH + href;

    const size = '2.5em';
    const style = {
        width: size,
        height: size,
    };

    if (isAura) {
        style.width = '6em';
    }

    if (!href) {
        return <span className="text-sm">{name}</span>;
    }

    return <Image src={src} className="ability" style={style} />;
};

export default UIAbilityPicture;
