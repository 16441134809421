import React from 'react';

import { useTranslation } from 'react-i18next';
import { Modal, Button } from 'react-bootstrap';
import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';

import { DRAWING_PATH } from '../config';

const imageList = [];
const imageCount = 300;
for (let i = 1; i <= imageCount; i += 1) {
    const src = `${DRAWING_PATH + (i < 10 ? `0${i}` : i)}.png`;
    imageList.push({ src });
}

const DrawingGallery = ({ show, onHide, setDrawing, setSelectedDrawing, scrollPosition }) => {
    const { t } = useTranslation();

    const pickImage = (src) => {
        setDrawing(src);
        setSelectedDrawing(src);
        onHide();
    };

    return (
        <Modal size="lg" show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{t('DRAWING_GALLERY_TITLE')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="gallery">
                    {imageList.map(({ src }, idx) => (
                        <Button variant="link" className="thumbnail" key={idx} onClick={() => pickImage(src)}>
                            <LazyLoadImage src={src} scrollPosition={scrollPosition} />
                        </Button>
                    ))}
                </div>
            </Modal.Body>
        </Modal>
    );
};
export default trackWindowScroll(DrawingGallery);
