import React, { useState, useEffect, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import {
    Container,
    Row,
    Col,
    Form,
    Button,
    OverlayTrigger,
    Tooltip,
    Accordion,
    useAccordionButton,
    AccordionContext,
} from 'react-bootstrap';
import { BiRefresh, BiImages, BiInfoCircle } from 'react-icons/bi';
import { FaEyeSlash, FaEye } from 'react-icons/fa';
import { Canvg } from 'canvg';
import axios from 'axios';

import { AbilityList, Card, DownloadButton, InputCount, TextDescription } from './UI';
import DrawingGallery from './DrawingGallery';

import { STATS as CONFIG, DRAWING_PATH, BASE_URL } from '../config';
import Abilities from '../config/AbilityList';
import CardTypeList from '../config/CardTypeList';
import DrawingBackgroundList from '../config/DrawingBackgroundList';

const SVG_ID = 'cardSVG';

const AccordionButton = ({ children, eventKey }) => {
    const { activeEventKey } = useContext(AccordionContext);
    const onClick = useAccordionButton(eventKey);

    const isActive = activeEventKey === eventKey;

    const style = {
        fontSize: '1.25em',
        marginRight: '0.25em',
    };

    return (
        <Button variant="secondary" onClick={onClick} className="d-flex flex-row align-items-center justify-content-center me-3">
            {isActive ? <FaEyeSlash style={style} /> : <FaEye style={style} />}
            {children}
        </Button>
    );
};

const UIForm = () => {
    const { t } = useTranslation();

    const defaultCardType = CardTypeList.filter(({ isDefault }) => isDefault)[0].value || CardTypeList[0].value;
    const defaultStats = CONFIG[defaultCardType];

    const [cardType, setCardType] = useState(defaultCardType);
    const [STATS, setStats] = useState(defaultStats);
    const [manaCount, setManaCount] = useState(defaultStats.MANA.DEFAULT);
    const [attackCount, setAttackCount] = useState(defaultStats.ATTACK.DEFAULT);
    const [healthCount, setHealthCount] = useState(defaultStats.HEALTH.DEFAULT);
    const [selectedAura, setSelectedAura] = useState(null);
    const [selectedAbilityList, setSelectedAbilityList] = useState(defaultStats.ABILITIES.DEFAULT);

    const [isLocked, setLocked] = useState(false);
    const [attackIsLocked, setAttackLocked] = useState(false);
    const [isAbilityLocked, setAbilityLocked] = useState(false);

    const [drawing, setDrawing] = useState(null);
    const [selectedDrawing, setSelectedDrawing] = useState(null);
    const [showDrawingGallery, setShowDrawingGallery] = useState(false);
    const [drawingBackground, setDrawingBackground] = useState('');
    const [checkRights, setCheckRights] = useState(false);
    const [checkPrinting, setCheckPrinting] = useState(false);
    const [cardTitle, setCardTitle] = useState('');
    const [signature, setSignature] = useState('www.clashofdecks.com');

    const [generatorCount, setGeneratorCount] = useState(0);

    const callMailer = (data) => {
        axios({
            method: 'POST',
            url: `${BASE_URL}mailer.php`,
            headers: {
                'content-type': 'application/json',
            },
            data,
        })
            .then((res) => {
                setGeneratorCount(+res.data);
            })
            .catch((err) => {
                console.log('error', err);
            });
    };

    useEffect(() => {
        callMailer();
    }, []);

    const getStatManaCost = (count, type) => {
        let cost = 0;
        switch (type) {
            case 'attack':
                if (cardType === 'incantation') {
                    cost = 0.5 * count;
                } else {
                    cost = 0.5 * (count - 1);
                }
                break;
            case 'health':
                cost = 0.5 * (count - 1);
                break;
        }

        return cost;
    };

    const getAbilityManaCost = (manaCost, surplus = 0) =>
        (manaCost[cardType] !== undefined ? manaCost[cardType] : manaCost.default) + surplus;

    const calculateRealCost = () => {
        let cost = STATS.MANA.DEFAULT;

        cost += getStatManaCost(attackCount, 'attack');
        cost += getStatManaCost(healthCount, 'health');

        if (selectedAura) {
            const surplus = selectedAura.manaCost.surplus || 1;
            cost += getAbilityManaCost(selectedAura.manaCost, surplus);
        }
        selectedAbilityList.forEach(({ manaCost }) => {
            cost += getAbilityManaCost(manaCost);
        });

        return cost;
    };

    const handleNumberChange = ({ target }, callback, previousValue, type) => {
        const min = parseInt(target.min);
        const max = parseInt(target.max);
        const value = parseInt(target.value);

        const valueIsValid = calculateRealCost() - getStatManaCost(previousValue, type) + getStatManaCost(value, type) <= STATS.MANA.MAX;

        if (value >= min && value <= max && valueIsValid && (!isLocked || value < previousValue)) {
            callback(value);
        }
    };

    const handleTextChange = ({ target }, callback) => {
        callback(target.value);
    };

    const isAbilitySelected = (ability) => selectedAbilityList.indexOf(ability) !== -1;

    const isAuraSelected = (ability) => selectedAura === ability;

    const countSelectedAbilities = () => {
        let count = selectedAbilityList.length;

        if (selectedAura) {
            count += 3;
        }

        return count;
    };

    const toggleAura = (ability) => {
        if (isAuraSelected(ability)) {
            setSelectedAura(null);
        } else {
            setSelectedAura(ability);
        }
    };

    const toggleAbility = (ability) => {
        const newAbilityList = [...selectedAbilityList];

        const nbSelectedAbilities = countSelectedAbilities();

        if (isAbilitySelected(ability)) {
            // Dé-selection
            if (nbSelectedAbilities - 1 >= STATS.ABILITIES.MIN) {
                const index = selectedAbilityList.indexOf(ability);
                newAbilityList.splice(index, 1);
            }
        } else if (nbSelectedAbilities + 1 <= STATS.ABILITIES.MAX) {
            // sélection
            newAbilityList.push(ability);
        } else {
            return;
        }

        setSelectedAbilityList(newAbilityList);
    };

    const isAuraDisabled = (ability) => {
        if (selectedAbilityList.length > 2) {
            return true;
        }

        return isLocked && !isAbilitySelected(ability);
    };

    const isAbilityDisabled = (ability) => {
        const { manaCost } = ability;
        const isSelfLocked = calculateRealCost() + getAbilityManaCost(manaCost) > STATS.MANA.MAX;

        return (isLocked || isAbilityLocked || isSelfLocked) && !isAbilitySelected(ability);
    };

    function imageSize(url) {
        const img = document.createElement('img');

        const promise = new Promise((resolve, reject) => {
            img.onload = () => {
                // Natural size is the actual image size regardless of rendering.
                // The 'normal' `width`/`height` are for the **rendered** size.
                const width = img.naturalWidth;
                const height = img.naturalHeight;

                // Resolve promise with the width and height
                resolve({ width, height });
            };

            // Reject promise on error
            img.onerror = reject;
        });

        // Setting the source makes it start downloading and eventually call `onload`
        img.src = url;

        return promise;
    }

    const displayDrawingGallery = () => {
        setShowDrawingGallery(true);
    };
    const dismissDrawingGallery = () => {
        setShowDrawingGallery(false);
    };

    const uploadDrawing = ({ target }) => {
        if (target.files && target.files[0]) {
            const file = target.files[0];

            // Taille minimale pour obtenir 300 DPI en imprimant sur 54 * 86 mm
            const minSize = {
                width: 650,
                height: 1024,
            };

            const url = URL.createObjectURL(file);
            imageSize(url).then(({ width, height }) => {
                if (width < minSize.width || height < minSize.height) {
                    const msg = `${t('DRAWING_SIZE_ERROR')} ${minSize.width}*${minSize.height}px`;
                    alert(msg);
                    setDrawing(null);
                } else {
                    setDrawing(url);
                }
            });
        }
    };

    const downloadCard = async () => {
        const now = new Date();
        const dateFormatter = Intl.DateTimeFormat('fr-FR');
        const date = dateFormatter.format(now);

        let name = date;
        if (signature) {
            name += `_${signature.replaceAll(' ', '-').toLowerCase()}`;
        }
        name += '.JPEG';

        const download = (href) => {
            const link = document.createElement('a');
            link.download = name;
            link.style.opacity = 0;
            link.href = href;
            link.click();
            link.remove();
        };

        const ratio = 1.5;

        const base = document.getElementById(SVG_ID);
        const baseWidth = Math.ceil(base.getAttribute('width'));
        const baseHeight = Math.ceil(base.getAttribute('height'));

        const svg = base.cloneNode(true);
        const canvas = document.createElement('canvas');
        svg.setAttribute('width', baseWidth * ratio);
        svg.setAttribute('height', baseHeight * ratio);
        svg.setAttribute('transform', `scale(${ratio}) translate(${baseWidth / 4} ${baseHeight / 4})`);

        canvas.width = baseWidth * ratio;
        canvas.height = baseHeight * ratio;

        const xml = new XMLSerializer().serializeToString(svg);
        const ctx = canvas.getContext('2d');
        const v = await Canvg.fromString(ctx, xml);
        await v.render();

        const picture = canvas.toDataURL('image/jpeg', 1.0);

        download(picture);
        svg.remove();

        callMailer({
            name: cardTitle,
            picture,
            sendEmail: checkPrinting,
        });
    };

    const canPickAura = () => cardType !== 'incantation';

    const generateRandomCard = () => {
        const randomNumber = (min, max) => Math.floor(min + Math.random() * (max - min + 1));

        const weightedRandomNumber = (conf) => {
            let j;
            const table = [];

            Object.keys(conf).forEach((i) => {
                // eslint-disable-next-line no-plusplus
                for (j = 0; j < conf[i] * 10; j++) {
                    table.push(i);
                }
            });

            return table[Math.floor(Math.random() * table.length)];
        };

        const randomDrawingNumber = randomNumber(1, 300);
        const randomDrawing = `${DRAWING_PATH + (randomDrawingNumber < 10 ? `0${randomDrawingNumber}` : randomDrawingNumber)}.png`;
        const randomDrawingBackground = DrawingBackgroundList[randomNumber(1, DrawingBackgroundList.length - 1)].href;

        const randomAttack = randomNumber(STATS.ATTACK.MIN, STATS.ATTACK.MAX);
        const randomHealth = randomNumber(STATS.HEALTH.MIN, STATS.HEALTH.MAX);

        let minCost = STATS.MANA.DEFAULT + getStatManaCost(randomAttack, 'attack') + getStatManaCost(randomHealth, 'health');
        let maxCost = STATS.MANA.MAX;

        let availableAuraAbilities = Abilities.filter(({ canBeAura }) => {
            if (typeof canBeAura === 'boolean') {
                return canBeAura === true;
            }
            return canBeAura[cardType] !== undefined ? canBeAura[cardType] : canBeAura.default;
        });
        availableAuraAbilities = availableAuraAbilities.filter(({ availableFor = [] }) => availableFor.indexOf(cardType) !== -1);
        availableAuraAbilities = availableAuraAbilities.filter(({ manaCost }) => minCost + getAbilityManaCost(manaCost, 1) <= maxCost);

        const pickAura = canPickAura() && availableAuraAbilities.length && randomNumber(1, 5) <= 3;

        let randomAura = null;
        if (pickAura) {
            const idx = randomNumber(0, availableAuraAbilities.length - 1);
            randomAura = availableAuraAbilities[idx];
            minCost += getAbilityManaCost(randomAura.manaCost, 1);
        }

        maxCost = randomNumber(minCost, maxCost);
        let maxAbilities = 2;
        if (!randomAura) {
            maxAbilities = weightedRandomNumber({
                0: 0.05,
                1: 0.275,
                2: 0.275,
                3: 0.275,
                4: 0.075,
                5: 0.05,
            });
        }

        const selectedAbilities = [];
        let isGenerationDone = false;
        while (!isGenerationDone) {
            const realCost = () => {
                let cost = minCost;

                selectedAbilities.forEach(({ manaCost }) => {
                    cost += getAbilityManaCost(manaCost);
                });

                return cost;
            };

            const selectedCost = realCost();

            let availableAbilities = Abilities.filter(({ availableFor = [] }) => availableFor.indexOf(cardType) !== -1);
            availableAbilities = availableAbilities.filter((ability) => selectedAbilities.indexOf(ability) === -1);
            availableAbilities = availableAbilities.filter(({ manaCost }) => selectedCost + getAbilityManaCost(manaCost) <= maxCost);

            const idx = randomNumber(0, availableAbilities.length - 1);
            if (availableAbilities[idx]) {
                selectedAbilities.push(availableAbilities[idx]);
            }

            if (!availableAbilities.length || selectedAbilities.length >= maxAbilities) {
                isGenerationDone = true;
            }
        }

        setAttackCount(randomAttack);
        setHealthCount(randomHealth);
        setSelectedAura(randomAura);
        setSelectedAbilityList(selectedAbilities);

        setDrawing(randomDrawing);
        setDrawingBackground(randomDrawingBackground);
    };

    useEffect(() => {
        const cost = Math.ceil(calculateRealCost());

        if (cost < STATS.MANA.MIN) {
            setManaCount(STATS.MANA.MIN);
        } else if (cost > STATS.MANA.MAX) {
            setManaCount(STATS.MANA.MAX);
        } else {
            setManaCount(cost);
        }
    }, [attackCount, healthCount, selectedAura, selectedAbilityList]);

    useEffect(() => {
        if (manaCount >= STATS.MANA.MAX) {
            setLocked(true);
        } else {
            setLocked(false);
        }
    }, [manaCount]);

    useEffect(() => {
        const nbSelectedAbilities = countSelectedAbilities();
        if (nbSelectedAbilities >= STATS.ABILITIES.MAX) {
            setAbilityLocked(true);
        } else {
            setAbilityLocked(false);
        }

        // Cas particulier de la Vulnérabilité
        const hasVulenaribility = selectedAbilityList.filter(({ name }) => name.en === 'Vulnerability').length;
        if (hasVulenaribility) {
            setAttackLocked(true);
            setAttackCount(1);
        } else {
            setAttackLocked(false);
        }
    }, [selectedAura, selectedAbilityList]);

    useEffect(() => {
        const currentStats = CONFIG[cardType];

        setStats(currentStats);
        setManaCount(currentStats.MANA.DEFAULT);
        setAttackCount(currentStats.ATTACK.DEFAULT);
        setHealthCount(currentStats.HEALTH.DEFAULT);
        setSelectedAbilityList(currentStats.ABILITIES.DEFAULT);
    }, [cardType]);

    let fullAbilityList = [];
    if (selectedAura) {
        fullAbilityList.push({ ...selectedAura, isAura: true });
    }
    fullAbilityList = fullAbilityList.concat(selectedAbilityList);

    return (
        <Container fluid className="pb-3 mb-4">
            <Row>
                <Col xs="auto" className="py-3">
                    <Card
                        svgId={SVG_ID}
                        cardType={cardType}
                        drawing={drawing}
                        drawingBackground={drawingBackground}
                        mana={manaCount}
                        attack={attackIsLocked ? 'x' : attackCount}
                        health={healthCount}
                        abilityList={fullAbilityList}
                        title={cardTitle}
                        signature={signature}
                    />
                </Col>
                <Col className="py-3 border-start">
                    <Form>
                        <Row className="mb-3">
                            <Col md={4} className="mb-1 mb-md-0">
                                <InputCount
                                    id="manaCount"
                                    label={t('MANA')}
                                    description={t('MANA_DESCRIPTION')}
                                    value={manaCount}
                                    readOnly
                                />
                            </Col>
                            <Col xs={6} md={4} className="mb-1 mb-md-0">
                                <InputCount
                                    id="attackCount"
                                    label={t('ATTACK')}
                                    min={STATS.ATTACK.MIN}
                                    max={STATS.ATTACK.MAX}
                                    value={attackCount}
                                    disabled={attackIsLocked}
                                    onChange={(e) => {
                                        handleNumberChange(e, setAttackCount, attackCount, 'attack');
                                    }}
                                />
                            </Col>
                            <Col xs={6} md={4} className="mb-1 mb-md-0">
                                {cardType !== 'incantation' && (
                                    <InputCount
                                        id="healthCount"
                                        label={t('HEALTH')}
                                        min={STATS.HEALTH.MIN}
                                        max={STATS.HEALTH.MAX}
                                        value={healthCount}
                                        onChange={(e) => handleNumberChange(e, setHealthCount, healthCount, 'health')}
                                    />
                                )}
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs={12} md={3}>
                                {t('ABILITIES')}
                            </Col>
                            <Col xs={12} md={9} className="d-flex flex-wrap align-items-center justify-content-start">
                                <AbilityList
                                    cardType={cardType}
                                    onClick={toggleAbility}
                                    isSelected={isAbilitySelected}
                                    disabled={isAbilityDisabled}
                                />
                            </Col>
                        </Row>
                        {canPickAura() && (
                            <Accordion defaultActiveKey="">
                                <Row className="mb-3 align-items-start">
                                    <Col xs={12} md={3} className="d-flex flex-row align-items-center">
                                        <AccordionButton eventKey="0">
                                            <span>{t('AURA')}</span>
                                        </AccordionButton>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip id="tooltip-top">
                                                    <div dangerouslySetInnerHTML={{ __html: t('AURA_INFO') }} />
                                                </Tooltip>
                                            }
                                        >
                                            <Button variant="transparent" className="p-0">
                                                <BiInfoCircle style={{ fontSize: '1.25em', cursor: 'pointer' }} />
                                            </Button>
                                        </OverlayTrigger>
                                    </Col>
                                    <Col xs={12} md={9} className="d-flex flex-wrap align-items-center justify-content-start">
                                        <Accordion.Collapse eventKey="0">
                                            <AbilityList
                                                auraOnly
                                                cardType={cardType}
                                                onClick={toggleAura}
                                                isSelected={isAuraSelected}
                                                disabled={isAuraDisabled}
                                            />
                                        </Accordion.Collapse>
                                    </Col>
                                </Row>
                            </Accordion>
                        )}
                        <Row className="mb-3">
                            <Col xs={12} md={6}>
                                <Form.Group controlId="cartType">
                                    <Form.Label>{t('CARD_TYPE')}</Form.Label>
                                    <Form.Select
                                        defaultValue={cardType}
                                        onChange={(e) => {
                                            handleTextChange(e, setCardType);
                                        }}
                                    >
                                        {CardTypeList.map(({ value, label }, idx) => (
                                            <option key={idx} value={value}>
                                                {t(label)}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6} className="d-flex align-items-end">
                                <Button
                                    variant="primary"
                                    className="d-flex flex-row align-items-center justify-content-center"
                                    onClick={generateRandomCard}
                                >
                                    <BiRefresh style={{ fontSize: '1.25em' }} />
                                    <span className="ms-2">{t('GENERATE_RANDOM_CARD')}</span>
                                </Button>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs={12} md={6}>
                                <Form.Group controlId="drawingBackground">
                                    <Form.Label>{t('DRAWING_BACKGROUND')}</Form.Label>
                                    <Form.Select
                                        value={drawingBackground}
                                        onChange={(e) => {
                                            handleTextChange(e, setDrawingBackground);
                                        }}
                                    >
                                        {DrawingBackgroundList.map(({ href, label }, idx) => (
                                            <option key={idx} value={href}>
                                                {t(label)}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="drawing">
                                    <Form.Label>{t('DRAWING')}</Form.Label>
                                    <br />
                                    <Button
                                        variant="primary"
                                        className="d-flex flex-row align-items-center justify-content-center my-2"
                                        onClick={displayDrawingGallery}
                                    >
                                        <BiImages style={{ fontSize: '1.25em' }} />
                                        <span className="ms-3">{t('OPEN_DRAWING_GALLERY')}</span>
                                    </Button>
                                    <Form.Control type="file" onChange={uploadDrawing} description={t('CHOOSE_FILE')} />
                                    <TextDescription>{t('DRAWING_DESCRIPTION')}</TextDescription>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group controlId="title">
                                <Form.Label>{t('CARD_TITLE')}</Form.Label>
                                <Form.Control type="text" value={cardTitle} onChange={(e) => handleTextChange(e, setCardTitle)} />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group controlId="signature">
                                <Form.Label>{t('CARD_SIGNATURE')}</Form.Label>
                                <Form.Control type="text" value={signature} onChange={(e) => handleTextChange(e, setSignature)} />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group controlId="checkRights">
                                <Form.Check
                                    type="checkbox"
                                    label={t('DRAWING_RIGHTS')}
                                    checked={checkRights}
                                    isInvalid={!checkRights}
                                    onChange={(e) => {
                                        setCheckRights(e.target.checked);
                                    }}
                                />
                            </Form.Group>
                            <Form.Group controlId="checkPrinting">
                                <Form.Check
                                    type="checkbox"
                                    label={t('PRINTING_SERVICE')}
                                    checked={checkPrinting}
                                    onChange={(e) => {
                                        setCheckPrinting(e.target.checked);
                                    }}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mt-5 text-end">
                            <Col xs={12} md={6} className="text-start">
                                {`${t('GENERATED_CARDS')} ${generatorCount}`}
                            </Col>
                            <Col xs={12} md={6}>
                                <DownloadButton label={t('CREATE_CARD')} onClick={downloadCard} disabled={!checkRights} />
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col className="text-center">
                    <a href="mailto:leandre@grammesedition.fr">{t('CONTACT')}</a>
                </Col>
                <Col className="text-center">
                    <a href="http://www.clashofdecks.com">www.clashofdecks.com</a>
                </Col>
                <Col className="text-center">
                    <a href="http://www.clashofdecks.com/conditions-generale-dutilisation" target="_blank" rel="noreferrer">
                        {t('TOS')}
                    </a>
                </Col>
            </Row>

            <DrawingGallery
                show={showDrawingGallery}
                onHide={dismissDrawingGallery}
                setDrawing={setDrawing}
                selectedDrawing={selectedDrawing}
                setSelectedDrawing={(p) => setSelectedDrawing(p)}
            />
        </Container>
    );
};
export default UIForm;
