export default [
    {
        isDefault: true,
        value: 'champion',
        label: 'CHAMPION',
    },
    {
        value: 'creature',
        label: 'CREATURE',
    },
    {
        value: 'incantation',
        label: 'INCANTATION',
    },
];
