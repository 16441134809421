/* eslint-disable no-undef */
import React from 'react';
import { createRoot } from 'react-dom/client';

import './styles/App.scss';
import './i18n/config';

import App from './App';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(<App />);

/*
// Before
import { render } from 'react-dom';
const container = document.getElementById('app');
render(<App tab="home" />, container);

// After
import { createRoot } from 'react-dom/client';
const container = document.getElementById('app');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App tab="home" />);
*/
