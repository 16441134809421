import React from 'react';

import { useTranslation } from 'react-i18next';

import ButtonAbility from './ButtonAbility';
import AbilityList from '../../config/AbilityList';

const UIAbilityList = ({ cardType, onClick, isSelected, disabled, auraOnly = false }) => {
    const { i18n } = useTranslation();

    let itemList = AbilityList;
    if (auraOnly) {
        console.log(cardType);
        // itemList = AbilityList.filter(({ canBeAura }) => canBeAura === true);
        itemList = AbilityList.filter(({ canBeAura }) => {
            if (typeof canBeAura === 'boolean') {
                return canBeAura === true;
            }
            return canBeAura[cardType] !== undefined ? canBeAura[cardType] : canBeAura.default;
        });
    }

    return itemList
        .filter(({ availableFor = [] }) => availableFor.indexOf(cardType) !== -1)
        .sort((a, b) => (a.name[i18n.language] > b.name[i18n.language] ? 1 : -1))
        .map((ability, idx) => (
            <ButtonAbility
                key={idx}
                href={ability.href}
                name={ability.name[i18n.language]}
                description={ability.description[i18n.language]}
                onClick={() => onClick(ability)}
                isSelected={isSelected(ability)}
                disabled={disabled(ability)}
            />
        ));
};

export default React.memo(UIAbilityList);
