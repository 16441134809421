import React from 'react';

import { useTranslation } from 'react-i18next';
import { Container, Navbar, Image, Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';

import { LanguageFlag } from './UI';
import LanguageList from '../config/LanguageList';

const UIHeader = () => {
    const { i18n, t } = useTranslation();

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    };

    const currentLanguage = LanguageList.find((language) => language.name === i18n.language);

    return (
        <Navbar bg="light" sticky="top">
            <Container fluid className="align-items-center justify-content-start">
                <Navbar.Brand href="#">
                    <Image src="/img/logo/logo192.png" style={{ height: '5em' }} />
                </Navbar.Brand>
                <div className="ps-md-3" style={{ height: '100%' }}>
                    <Navbar.Brand className="text-secondary">{t('SLOGAN')}</Navbar.Brand>
                </div>
                <Dropdown onSelect={changeLanguage} className="ms-auto">
                    <DropdownButton
                        as={ButtonGroup}
                        id="language-dropdown"
                        variant="transparent"
                        title={<LanguageFlag {...currentLanguage} />}
                    >
                        {LanguageList.map((language, idx) => (
                            <Dropdown.Item key={idx} eventKey={language.name} active={language.name === currentLanguage.name}>
                                <LanguageFlag {...language} />
                            </Dropdown.Item>
                        ))}
                    </DropdownButton>
                </Dropdown>
            </Container>
        </Navbar>
    );
};

export default UIHeader;
