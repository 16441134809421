import { useState, useEffect, useRef } from 'react';

const interval = 35;

const useZoom = (hasDrawing, ref) => {
    const timer = useRef(null);
    let startTime;

    const [info, setInfo] = useState({
        scale: 1,
        transform: '',
        pinching: false,
        distance: 0,
    });

    const setScale = (scale) => {
        setInfo({
            scale,
            transform: `scale(${scale})`,
        });
    };

    const handleMouseWheel = (e) => {
        if (!hasDrawing) {
            return;
        }
        e.preventDefault();

        const minimumScale = 0.125;
        const ratio = 0.0005;

        let { scale } = info;
        scale += e.deltaY * (-1 * ratio);
        scale = Math.min(Math.max(minimumScale, scale), 4);

        setScale(scale);
    };

    const getOffset = () => Math.floor(Date.now() - startTime) / 10000;

    const zoomIn = () => {
        if (!hasDrawing) {
            return;
        }

        let { scale } = info;
        startTime = Date.now();

        timer.current = setInterval(() => {
            scale += getOffset();
            setScale(scale);
        }, interval);
    };

    const zoomOut = () => {
        if (!hasDrawing) {
            return;
        }

        let { scale } = info;
        startTime = Date.now();

        timer.current = setInterval(() => {
            scale -= getOffset();
            setScale(scale);
        }, interval);
    };

    const clearTimer = () => {
        clearInterval(timer.current);
    };

    useEffect(() => {
        if (!ref.current) {
            return false;
        }

        ref.current.addEventListener('wheel', handleMouseWheel, { passive: false });

        return () => {
            ref.current.removeEventListener('wheel', handleMouseWheel);
        };
    });

    const elementScale = {
        transform: info.transform,
    };

    return {
        setScale,
        elementScale,
        handleMouseWheel,
        zoomIn,
        zoomOut,
        clearTimer,
    };
};

export default useZoom;
