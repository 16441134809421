import React from 'react';

import { BACKGROUND_PATH, ABILITY_PATH } from '../../../config';

const UICardAbility = ({ size, offset, href, isAura = false, position }) => {
    const auraWidth = (size + offset) * 3;
    const src = ABILITY_PATH + href;

    let defaultProps = {
        x: position.x - size / 2,
        y: position.y,
        width: size - offset,
        height: size - offset,
    };

    const auraProps = {
        x: position.x - auraWidth / 2,
        y: position.y - offset / 2,
        width: auraWidth,
        height: size + offset,
        href: `${ABILITY_PATH}aura.png`,
    };

    if (isAura) {
        defaultProps = {
            ...defaultProps,
            y: auraProps.y + offset / 2,
        };
    }

    const backgroundProps = {
        ...defaultProps,
        width: defaultProps.width + offset,
        height: defaultProps.height + offset,
        href: `${BACKGROUND_PATH}ability.png`,
    };

    const abilityProps = {
        ...defaultProps,
        x: defaultProps.x + offset / 2,
        y: defaultProps.y + offset / 2,
        href: ABILITY_PATH + href,
    };

    return (
        <g>
            {isAura && <image {...auraProps} />}
            <image {...backgroundProps} />
            <image {...abilityProps} href={src} />
        </g>
    );
};

export default UICardAbility;
