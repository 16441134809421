export default [
    {
        name: {
            fr: 'Aérienne',
            en: 'Aerial',
        },
        description: {
            fr: "<b>Aérienne</b> : Si <i>possesseur</i> est dans la Ligne du haut, il ne peut pas attaquer les Créatures adverses. Il attaque directement la Forteresse adverse, en ignorant les Créatures adverses. Octroyer aussi la capacité spéciale Rage au <i>possesseur</i> qui est sur la Ligne du haut n'a aucun effet. Octroyer aussi la capacité spéciale Défenseur au <i>possesseur</i> qui est sur la Ligne du haut l'empêcherait d'attaquer aussi bien les Créatures que la Forteresse adverse.",
            en: '<b>Aerial</b>: If the <i>owner</i> is on the upper line, it cannot attack enemy Creatures. It directly attacks the enemy Stronghold, ignoring enemy Creatures. Additionally granting the Rage Special Ability to the <i>owner</i> on the upper line has no effect. Additionally granting the Defender Special Ability to the <i>owner</i> on the upper line would prevent it from attacking Creatures as well as the enemy Stronghold.',
        },
        href: 'aerienne.png',
        manaCost: {
            default: 0.5,
        },
        canBeAura: true,
        availableFor: ['champion', 'creature'],
    },
    {
        name: {
            fr: 'Affûtage',
            en: 'Sharpening',
        },
        description: {
            fr: "<b>Affûtage</b> : Lorsque le <i>possesseur</i> inflige assez de Blessures pour détruire une Créature, chaque Blessure excédentaire est infligée à la Forteresse adverse. Si plusieurs Créatures sont détruites par une même attaque (grâce à Splash ou Perforation par exemple), les Blessures excédentaires sont infligées en une seule fois à la Forteresse adverse. <i>possesseur</i> n'a aucun effet sur une Créature ayant la capacité spéciale Protection, puisqu'en réduisant la source de Blessures à 0, il n'y a pas de dégâts excédentaires.",
            en: '<b>Sharpening</b>: When the <i>owner</i> deals enough Damage to destroy a Creature, any excess Damage is dealt to the enemy Stronghold. If more than one Creature is destroyed by a single attack (i.e. with Splash or Perforation),excess Damage is dealt to the enemy Stronghold in a single instance. The <i>owner</i> has no effect on a Creature with the Protection Special Ability, as reducing the Damage source to 0 generates no excess Damage.',
        },
        href: 'affutage.png',
        manaCost: {
            default: 2,
            incantation: 3,
        },
        canBeAura: true,
        availableFor: ['champion', 'creature', 'incantation'],
    },
    {
        name: {
            fr: 'Aquatique',
            en: 'Aquatic',
        },
        description: {
            fr: "<b>Aquatique</b> : Tant que <i>possesseur</i> est adjacent à un Pont, il inflige le double de Blessures. Si <i>possesseur</i> bénéficie de la capacité spéciale Sprint, le bonus d'attaque octroyé par le Sprint sera aussi doublé.",
            en: '<b>Aquatic</b>: As long as the <i>owner</i> is adjacent to a Bridge, it deals double Damage. If the <i>owner</i> has the Sprint Special Ability, the attack bonus granted by Sprint will also be doubled.',
        },
        href: 'aquatique.png',
        manaCost: {
            default: 1,
        },
        canBeAura: true,
        availableFor: ['champion', 'creature'],
    },
    {
        name: {
            fr: 'Berserk',
            en: 'Berserk',
        },
        description: {
            fr: "<b>Berserk</b> : Chaque fois que <i>possesseur</i> attaque et détruit au moins une Créature adverse, il attaque une nouvelle fois. Résolvez toutes les attaques du <i>possesseur</i> avant de résoudre les attaques et effets des prochaines Créatures. Après avoir détruit la dernière Créature adverse de sa Ligne, comme il doit attaquer de nouveau, il attaque la Forteresse adverse (sauf s'il attaque lors de son tour d'arrivée en jeu grâce à la capacité spéciale Rage).",
            en: '<b>Berserk</b>: Each time the <i>owner</i> attacks and destroys at least one enemy Creature, it attacks again. Resolve all the attacks of the <i>owner</i> before resolving the attacks and effects of the next Creatures. After destroying the last enemy Creature on its line, because it must attack again, it attacks the enemy Stronghold (unless it attacks during the turn it entered play thanks to the Rage Special Ability).',
        },
        href: 'berserk.png',
        manaCost: {
            default: 1,
            incantation: 2,
        },
        canBeAura: true,
        availableFor: ['champion', 'creature', 'incantation'],
    },
    {
        name: {
            fr: 'Catalyseur',
            en: 'Catalyst',
        },
        description: {
            fr: "<b>Catalyseur</b> : Si <i>possesseur</i> est en jeu lors de votre Phase 1, gagnez +2 Mana ce tour. Il n'y a pas de limite au nombre de Mana qu'un joueur peut obtenir lors de la Phase 1 : Régénération du mana.",
            en: '<b>Catalyst</b>: If the <i>owner</i> is in play during your Phase 1, gain +2 Mana this turn. There is no limit to the amount of Mana a player can gain during Phase 1: Mana Regeneration.',
        },
        href: 'catalyseur.png',
        manaCost: {
            default: 1,
        },
        canBeAura: true,
        availableFor: ['champion', 'creature'],
    },
    {
        name: {
            fr: 'Défenseur',
            en: 'Defender',
        },
        description: {
            fr: '<b>Défenseur</b> : <i>possesseur</i> peut attaquer les Créatures adverses, mais ne peut pas attaquer la Forteresse adverse. Cette capacité spéciale est un malus. En contrepartie, <i>possesseur</i> est intrinsèquement plus puissant que les autres cartes ayant le même coût de mana.',
            en: '<b>Defender</b>: The <i>owner</i> can attack enemy Creatures but cannot attack the enemy Stronghold. This Special Ability is a malus. The <i>owner</i> is inherently stronger than other cards with the same Mana cost to compensate.',
        },
        href: 'defenseur.png',
        manaCost: {
            default: -1,
            surplus: -1,
        },
        canBeAura: false,
        availableFor: ['champion', 'creature'],
    },
    {
        name: {
            fr: 'Dérobade',
            en: 'Vanish',
        },
        description: {
            fr: "<b>Dérobade</b> : Après que <i>possesseur</i> a subi au moins 1 Blessure sans être détruit, déplacez-le à l'emplacement le plus éloigné du Pont dans sa Ligne. Si c'est une Créature qui a attaqué <i>possesseur</i>, l'effet de la capacité spéciale 'bade se déclenche une fois que la Créature qui l'a attaqué a terminé de résoudre toutes ses attaques. La capacité spéciale 'bade se déclenche avant la capacité spéciale Instinct.",
            en: '<b>Vanish</b>: After the <i>owner</i> has suffered at least 1 Damage without being destroyed, move it to the furthest position away from the Bridge on its line. If the <i>owner</i> was attacked by a Creature, Vanish triggers after the attacking Creature has finished and resolved each one of its attacks. Vanish triggers before the Instinct Special Ability.',
        },
        href: 'derobade.png',
        manaCost: {
            default: 0.5,
        },
        canBeAura: true,
        availableFor: ['champion', 'creature'],
    },
    {
        name: {
            fr: 'Indestructible',
            en: 'Indestructible',
        },
        description: {
            fr: '<b>Indestructible</b> : <i>possesseur</i> ne subit pas les effets et les Blessures provenant des Incantations (il peut tout de même être ciblé).',
            en: '<b>Indestructible</b>: The <i>owner</i> does not suffer Damage or effects from Incantations (it can still be targeted).',
        },
        href: 'indestructible.png',
        manaCost: {
            default: 0.5,
        },
        canBeAura: true,
        availableFor: ['champion', 'creature'],
    },
    {
        name: {
            fr: 'Instinct',
            en: 'Instinct',
        },
        description: {
            fr: "<b>Instinct</b> : Après que <i>possesseur</i> a subi au moins 1 Blessure d'une Créature sans être détruit, il attaque la Créature adverse se trouvant le plus près du Pont dans sa Ligne une fois que la Créature qui l'a attaqué a terminé de résoudre toutes ses attaques. S'il n'y a pas de Créature adverse, <i>possesseur</i> attaque la Forteresse adverse. La capacité spéciale Instinct se déclenche après la capacité spéciale 'bade.",
            en: '<b>Instinct</b>: After the <i>owner</i> has suffered at least 1 Damage from a Creature without being destroyed, it attacks the Creature closest  to the Bridge on its line, after each of the attacks of the Creature attacking it are resolved. If there is no enemy Creature, the <i>owner</i> attacks the enemy Stronghold. The Instinct Special Ability activates after the Vanish Special Ability.',
        },
        href: 'instinct.png',
        manaCost: {
            default: 1,
        },
        canBeAura: true,
        availableFor: ['champion', 'creature'],
    },
    {
        name: {
            fr: 'Mercenaire',
            en: 'Mercenary',
        },
        description: {
            fr: "<b>Mercenaire</b> : Lorsque <i>possesseur</i> devrait retourner dans la main de son propriétaire, à la place il retourne à l'extrémité droite de la main de son adversaire (ou au-dessus de la pioche IA en partie Solo). Cette capacité spéciale est un malus. En contrepartie <i>possesseur</i> est intrinsèquement plus puissant que les autres cartes ayant le même coût de mana.",
            en: "<b>Mercenary</b>: When the <i>owner</i> should return to their owner's hand, it returns to the rightmost position in the enemy's hand instead (or on top of the AI's deck pile in Solo play). This Special Ability is a malus. The <i>owner</i> is inherently more powerful than other cards with the same mana cost to compensate.",
        },
        href: 'mercenaire.png',
        manaCost: {
            default: -1,
        },
        canBeAura: {
            default: false,
            creature: true,
        },
        availableFor: ['creature', 'incantation'],
    },
    {
        name: {
            fr: 'Symbiote',
            en: 'Symbiote',
        },
        description: {
            fr: "<b>Symbiote</b> : Lorsque <i>possesseur</i> est posé, il peut être placé par-dessus une autre Créature de son Royaume déjà présente en jeu. La VA, les PV et les Capacités spéciales des deux Créatures se cumulent tant qu'elles sont en jeu. Lorsqu'elles sont détruites, les deux cartes remontent dans la main de leur propriétaire, en commençant par remonter <i>possesseur</i>. <i>possesseur</i> peut aussi être posé en jeu comme une Créature indépendante. <i>possesseur</i> ne peut pas être placé par-dessus une autre Créature ayant déjà la capacité spéciale Symbiote. Avec Symbiote, la Vulnérabilité ne se cumule pas avec la VA de l'autre créature, ignorez la VA indiquée et appliquez seulement la Vulnérabilité.",
            en: "<b>Symbiote</b>: When the <i>owner</i> enters play, it can be placed above another Creature already in play in its Kingdom. The VA, HP and Special Abilities of both Creatures stack as long as they remain in play. When destroyed, both cards are returned to their owner's hand, starting with the <i>owner</i>. The <i>owner</i> can also enter play as an independent Creature. The <i>owner</i> cannot be placed above another Creature that already has the Symbiote Special Ability. Symbiote doesn't stack Vulnerability with the other Creature's AV. Ignore the indicated AV, only applying Vulnerability.",
        },
        href: 'parasite.png',
        manaCost: {
            default: 2,
        },
        canBeAura: false,
        availableFor: ['champion', 'creature'],
    },
    {
        name: {
            fr: 'Perforation',
            en: 'Perforation',
        },
        description: {
            fr: "<b>Perforation</b> : <i>possesseur</i> inflige ses Blessures à la Créature ciblée ET à la Créature située derrière elle, dans la même Ligne. Cet effet est appliqué uniquement sur des Créatures, jamais sur la Forteresse adverse. Les Blessures d'une attaque avec plusieurs cibles se résolvent simultanément.",
            en: '<b>Perforation</b>: The <i>owner</i> deals its Damage to the target Creature AND to the Creature located behind it on the same line. This effect only applies to Creatures, never to the enemy Stronghold. Damage from an attack with more than one target is resolved in a single instance.',
        },
        href: 'perforation.png',
        manaCost: {
            default: 1,
            incantation: 2,
        },
        canBeAura: true,
        availableFor: ['champion', 'creature', 'incantation'],
    },
    {
        name: {
            fr: 'Prix du sang',
            en: 'Price of Blood',
        },
        description: {
            fr: "<b>Prix du sang</b> : Si vous n'avez pas assez de Mana pour payer l'intégralité du coût de <i>possesseur</i>, dépensez l'intégralité de votre Mana restant, et pour chaque Mana manquant, subissez une Blessure en décalant votre Forteresse d'un emplacement vers la droite dans votre main. Les Blessures ainsi subies sont infligées une à une.",
            en: "<b>Price of Blood</b>: If you do not have enough Mana to pay for the <i>owner</i>'s full cost, spend all your remaining Mana, then for each missing Mana, suffer one Damage by shifting your Stronghold one position to the right in your hand. Damage suffered this way is dealt in instances of 1.",
        },
        href: 'prix-du-sang.png',
        manaCost: {
            default: 2,
            incantation: 3,
        },
        canBeAura: false,
        availableFor: ['champion', 'creature', 'incantation'],
    },
    {
        name: {
            fr: 'Protection',
            en: 'Protection',
        },
        description: {
            fr: "<b>Protection</b> : Lors de chaque tour, réduit à 0 la première source de Blessures dont <i>possesseur</i> est la cible. Est considéré comme une source de Blessures n'importe quelle Créature ou Incantation infligeant au moins 1 Blessure. Les effets de cette source de Blessures sont tout de même déclenchés (Splash, Perforation, etc.) et sont infligés normalement aux autres Créatures.",
            en: '<b>Protection</b>: During your turn, Protection reduces the first source of Damage targeting the <i>owner</i> to 0. Sources of Damage include any Creature or Incantation dealing at least 1 Damage. Effects from these sources of Damage are still triggered (Splash, Perforation, etc.) and dealt to other Creatures as normal.',
        },
        href: 'protection.png',
        manaCost: {
            default: 2,
        },
        canBeAura: true,
        availableFor: ['champion', 'creature'],
    },
    {
        name: {
            fr: 'Rage',
            en: 'Rage',
        },
        description: {
            fr: "<b>Rage</b> : <i>possesseur</i> peut attaquer des Créatures adverses pendant le tour où il est posé en jeu. Cet effet ne permet pas d'attaquer la Forteresse adverse pendant le tour où <i>possesseur</i> est posé. S'il n'y a aucune Créature adverse à attaquer le tour où <i>possesseur</i> est posé, la capacité spéciale Rage est sans effet.",
            en: '<b>Rage</b>: The <i>owner</i> can attack enemy Creatures during the turn it enters play. This effect does not allow the <i>owner</i> to attack the enemy Stronghold the turn it enters play. If no enemy Creature can be attacked the turn the <i>owner</i> enters play, the Rage Special Ability has no effect.',
        },
        href: 'rage.png',
        manaCost: {
            default: 1.5,
        },
        canBeAura: true,
        availableFor: ['champion', 'creature'],
    },
    {
        name: {
            fr: 'Régénération',
            en: 'Regeneration',
        },
        description: {
            fr: "<b>Régénération</b> : Chaque fois que <i>possesseur</i> inflige des Blessures à une cible, décalez la Forteresse de son propriétaire d'un emplacement vers la gauche dans sa main. Si le Fort doit être décalé vers la gauche et qu'il est déjà à l'extrémité gauche de la main de son propriétaire, le Fort est alors pivoté afin que le Bastion soit à l'endroit, puis déplacé jusqu'à être la deuxième carte la plus à droite de la main de son propriétaire.",
            en: "<b>Regeneration</b>: Each time the <i>owner</i> deals Damage to a target, shift the card owner's Stronghold one position to the left in their hand. If the Fort should shift left while it is already in the leftmost position in its owner's hand, the Fort is then rotated so the Bastion is side up, then moved to the second rightmost position in its owner's hand.",
        },
        href: 'regeneration.png',
        manaCost: {
            default: 1,
        },
        canBeAura: true,
        availableFor: ['champion', 'creature', 'incantation'],
    },
    {
        name: {
            fr: 'Splash',
            en: 'Splash',
        },
        description: {
            fr: "<b>Splash</b> : <i>possesseur</i> inflige ses Blessures à la Créature ciblée ET à la Créature située à la même place dans la Ligne voisine. Cet effet est appliqué uniquement sur des Créatures, jamais sur la Forteresse adverse. Les Blessures d'une attaque avec plusieurs cibles se résolvent simultanément.",
            en: '<b>Splash</b>: The <i>owner</i> deals its Damage to the target Creature AND to the Creature in the same position in the neighbouring lane. This effect only applies to Creatures, never to the enemy Stronghold. Damage dealt to more than one target is resolved in a single instance.',
        },
        href: 'splash.png',
        manaCost: {
            default: 1,
        },
        canBeAura: true,
        availableFor: ['champion', 'creature', 'incantation'],
    },
    {
        name: {
            fr: 'Sprint',
            en: 'Sprint',
        },
        description: {
            fr: "<b>Sprint</b> : Chaque fois que <i>possesseur</i> attaque, déplacez-le jusqu'à ce qu'il soit le plus proche de la carte Pont. Pour ce tour, <i>possesseur</i> inflige +X Blessures, où X est égal au nombre de Créatures par-dessus lesquelles il s'est déplacé. Il n'attaque qu'une seule fois mais sa VA est modifiée jusqu'à la fin de la Phase 3 : Assaut.",
            en: '<b>Sprint</b>: Each time the <i>owner</i> attacks, move it until it is adjacent to the Bridge card on the same line. This turn, the <i>owner</i> deals +X Damage, where X = the number of Creatures it moved over. It only attacks once, but its AV is modified until the end of Phase 3: Assault.',
        },
        href: 'sprint.png',
        manaCost: {
            default: 0.5,
        },
        canBeAura: true,
        availableFor: ['champion', 'creature'],
    },
    {
        name: {
            fr: 'Vulnérabilité',
            en: 'Vulnerability',
        },
        description: {
            fr: "<b>Vulnérabilité</b> : La VA de <i>possesseur</i> inflige X Blessures à une Créature adverse ou à la Forteresse adverse, où X est égal aux PV de la cible -1. Si <i>possesseur</i> attaque plusieurs cibles (grâce à Splash ou Perforation par exemple), cet effet agit sur toutes les cibles de l'attaque. Cette Capacité spéciale ne détruit pas la cible mais l'affaiblit. Mais associée avec les Capacités spéciales Aquatique ou Sprint, <i>possesseur</i> peut détruire n'importe quelle cible en une seule attaque. Si <i>possesseur</i> attaque la Forteresse adverse, cela la déplace juste avant la carte à l'extrémité droite de sa main, sans changer son orientation (le Bastion reste orienté en Bastion).",
            en: "<b>Vulnerability</b>: The <i>owner</i>'s AV deals X Damage to an enemy Creature or Stronghold, where X = the target's HP -1. If the <i>owner</i> attacks more than one target (i.e. with Splash or Perforation), the effects applies to each target. This Special Ability weakens the target rather than destroying it. Paired with Special Abilities such as Sprint or Aquatic, the <i>owner</i> can destroy any target in a single attack. If the <i>owner</i> attacks the enemy Stronghold, it is shifted to the second rightmost position in the enemy's hand, without changing its side (i.e. the Bastion would remain side up).",
        },
        href: 'vulnerabilite.png',
        manaCost: {
            default: 1,
        },
        canBeAura: true,
        availableFor: ['champion', 'creature'],
    },
    {
        name: {
            fr: 'Chantage',
            en: 'Blackmail',
        },
        description: {
            fr: '<b>Chantage</b> : Tant que <i>possesseur</i> est en jeu, la carte la plus à droite de vos cartes disponibles en main ne peut plus être jouée (ne peut pas être inférieur à 1).',
            en: '<b>Blackmail</b>: As long as the <i>owner</i> is in play, the rightmost card of your available cards in hand can no longer be played (cannot be less than 1 card).',
        },
        href: 'blackmail.png',
        manaCost: {
            default: -1,
            surplus: -1,
        },
        canBeAura: true,
        availableFor: ['champion', 'creature'],
    },
    {
        name: {
            fr: 'Contre-ordre',
            en: 'Counter-order',
        },
        description: {
            fr: "<b>Contre-ordre</b> : Lorsque <i>possesseur</i> est joué, déplacez la carte de créature ou incantation la plus à gauche à l'extrémité droite de la main de son propriétaire.",
            en: "<b>Counter-order</b>: When the <i>owner</i> is played, move the leftmost Creature or Incantation card to the rightmost position in its owner's hand.",
        },
        href: 'counter-order.png',
        manaCost: {
            default: 0.5,
        },
        canBeAura: false,
        availableFor: ['champion', 'creature', 'incantation'],
    },
    {
        name: {
            fr: 'Enchainer',
            en: 'Enchain',
        },
        description: {
            fr: "<b>Enchainer</b> : Lorsque <i>possesseur</i> est joué, récupérez la carte créature ou incantation la plus à gauche de la main de votre adversaire. Révélez-la et placez-la, face cachée, sous <i>possesseur</i>. Lorsque <i>possesseur</i> a subi au moins 1 Blessure, la carte face cachée retourne à l'extrémité droite de la main de son propriétaire.",
            en: "<b>Enchain</b>: When the <i>owner</i> is played, take the leftmost Creature or Incantation card from your opponent's hand. Reveal it and place it face down under the <i>owner</i>. When the <i>owner</i> suffers at least 1 Damage, return the facedown card to the rightmost position in its owner's hand.",
        },
        href: 'enchain.png',
        manaCost: {
            default: 2,
        },
        canBeAura: false,
        availableFor: ['champion', 'creature'],
    },
    {
        name: {
            fr: 'Immolation',
            en: 'Immolation',
        },
        description: {
            fr: "<b>Immolation</b> : Tant que <i>possesseur</i> est en jeu, à chaque fois qu'un joueur joue une carte sur la même ligne, ce joueur subit 1 Blessure sur sa Forteresse.",
            en: '<b>Immolation</b>: As long as the <i>owner</i> is in play, each time a player plays a card on the same line, they suffer 1 Damage to their Stronghold.',
        },
        href: 'immolation.png',
        manaCost: {
            default: 0.5,
        },
        canBeAura: true,
        availableFor: ['champion', 'creature'],
    },
    {
        name: {
            fr: 'Lucidité',
            en: 'Lucidity',
        },
        description: {
            fr: "<b>Lucidité</b> : Si <i>possesseur</i> est la carte de créature ou d'incantation la plus à gauche de la main, elle coûte 2 de de mana de moins.",
            en: "<b>Lucidity</b>: If the <i>owner</i> is the leftmost Creature or Incantation card in its owner's hand, it costs 2 less Mana.",
        },
        href: 'lucidity.png',
        manaCost: {
            default: 1,
        },
        canBeAura: false,
        availableFor: ['creature', 'incantation'],
    },
    {
        name: {
            fr: 'Maudit',
            en: 'Cursed',
        },
        description: {
            fr: "<b>Maudit</b> : Lorsque <i>possesseur</i> devrait retourner à l'extrémité droite dans la main de son propriétaire, à la place il retourne à l'extrémité gauche.",
            en: "<b>Cursed</b>: When the <i>owner</i> should return to the rightmost position in its owner's hand, it returns to the leftmost position instead.",
        },
        href: 'cursed.png',
        manaCost: {
            default: -0.5,
            incantation: 0.5,
        },
        canBeAura: {
            default: false,
            creature: true,
        },
        availableFor: ['creature', 'incantation'],
    },
    {
        name: {
            fr: 'Renfort',
            en: 'Reinforcement',
        },
        description: {
            fr: '<b>Renfort</b> : Lorsque<i>possesseur</i>retourne dans la main de son propriétaire, jouez immédiatement sur la même ligne, sans payer son coût de mana, la première créature disponible de votre main la plus à gauche qui possède un coût de Mana de 4 ou moins et qui ne possède pas la Capacité spéciale Renfort.',
            en: "<b>Reinforcement</b>: When the<i>owner</i>returns to its owner's hand, immediately play the leftmost available Creature from your hand with a Mana cost of 4 or less that does not have the Reinforcement Special Ability.",
        },
        href: 'reinforcement.png',
        manaCost: {
            default: 2,
        },
        canBeAura: true,
        availableFor: ['champion', 'creature'],
    },
    {
        name: {
            fr: 'Retour de flammes',
            en: 'Backlash',
        },
        description: {
            fr: "<b>Retour de flammes</b> : Lorsque<i>possesseur</i>est joué, infligez à la créature adverse la plus proche du pont, sur la même ligne, autant de Blessures que le nombre de cartes situées dans votre main à gauche de votre Forteresse. Se combine avec toutes les capacités spéciales  qui s'activent avec des dégâts infligés comme Splash, Perforation, Aquatique, Régénération, Affutage... mais pas avec Berserk qui s'active avec une attaque de Créature.",
            en: '<b>Backlash</b>: When the<i>owner</i>enters play, deal to the enemy creature closest to the bridge in the same line damage equal to the number of cards at the left of your Stronghold in your hand. Combines with all special abilities that activate with damage inflicted such as Splash, Perforation, Aquatic, Regeneration, Sharpening... but not with Berserk which activates with a Creature attack.',
        },
        href: 'backlash.png',
        manaCost: {
            default: 1,
        },
        canBeAura: false,
        availableFor: ['champion', 'creature', 'incantation'],
    },
];
