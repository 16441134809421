import React from 'react';

const UICardNumber = ({ label, ...rest }) => {
    const defaultProps = {
        fill: '#fff',
        fontSize: 60,
        fontFamily: 'Familiar',
        stroke: 'rgba(255,255,255,0.2)',
        strokeWidth: 1,
        ...rest,
    };

    const style = {
        textAnchor: 'middle',
        dominantBaseline: 'middle',
    };

    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g>
                <text {...defaultProps} style={style}>
                    {label}
                </text>
            </g>
        </svg>
    );
};

export default UICardNumber;
