import React from 'react';

import Header from './components/Header';
import Form from './components/Form';

const App = () => (
    <>
        <Header />
        <Form />
    </>
);

export default App;
