export default [
    {
        label: 'NONE',
        href: '',
    },
    {
        label: 'DESERT',
        href: 'desert.jpg',
    },
    {
        label: 'FOREST',
        href: 'forest.jpg',
    },
    {
        label: 'GRAVEYARD',
        href: 'graveyard.jpg',
    },
    {
        label: 'MOUNTAIN',
        href: 'mountain.jpg',
    },
    {
        label: 'PLAINS',
        href: 'plains.jpg',
    },
    {
        label: 'SWAMP',
        href: 'swamp.jpg',
    },
];
