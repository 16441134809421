const STATS = {
    champion: {
        MANA: {
            DEFAULT: 0,
            MIN: 0,
            MAX: 9,
        },
        ATTACK: {
            DEFAULT: 1,
            MIN: 1,
            MAX: 9,
        },
        HEALTH: {
            DEFAULT: 1,
            MIN: 1,
            MAX: 9,
        },
        ABILITIES: {
            DEFAULT: [],
            MIN: 0,
            MAX: 5,
        },
    },
    creature: {
        MANA: {
            DEFAULT: 0,
            MIN: 0,
            MAX: 9,
        },
        ATTACK: {
            DEFAULT: 1,
            MIN: 1,
            MAX: 9,
        },
        HEALTH: {
            DEFAULT: 1,
            MIN: 1,
            MAX: 9,
        },
        ABILITIES: {
            DEFAULT: [],
            MIN: 0,
            MAX: 5,
        },
    },
    incantation: {
        MANA: {
            DEFAULT: 1,
            MIN: 1,
            MAX: 9,
        },
        ATTACK: {
            DEFAULT: 0,
            MIN: 0,
            MAX: 9,
        },
        HEALTH: {
            DEFAULT: 0,
            MIN: 0,
            MAX: 0,
        },
        ABILITIES: {
            DEFAULT: [],
            MIN: 0,
            MAX: 5,
        },
    },
};

const LANGUAGE = {
    DEFAULT: 'en',
    FALLBACK: 'en',
};

const BACKGROUND_PATH = '/img/backgrounds/';
const ABILITY_PATH = '/img/abilities/';
const DRAWING_BACKGROUND_PATH = '/img/drawings-backgrounds/';
const DRAWING_PATH = '/img/drawings/';

const BASE_URL = 'https://cardgenerator.clashofdecks.com/';

export { STATS, LANGUAGE, BACKGROUND_PATH, ABILITY_PATH, DRAWING_BACKGROUND_PATH, DRAWING_PATH, BASE_URL };
